import { ForwardedRef } from 'react'

import { AxiosError } from 'axios'
import { isArray, isObject } from 'lodash'
import qs from 'qs'

import { CONSTANTS } from '../constants'

export * from './redux'

export { shallowEqual } from 'react-redux'

export function assert<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(`Expected 'val' to be defined, but received ${val}`)
  }
}

export function assignRef<T>(
  ref: ForwardedRef<T> | undefined,
  value: T | null // add `null` here because ForwardedRef adds it implicitly
): void {
  if (ref === null || ref === undefined) return

  if (typeof ref === 'function') {
    ref(value)
  } else {
    ref.current = value
  }
}

export function combineRefs<T>(...args: Array<ForwardedRef<T> | undefined>) {
  const refs = args.filter(x => x !== undefined)
  if (refs.length < 2) return refs[0]
  // add `null` here because ForwardedRef adds it implicitly
  return (value: T | null) => {
    refs.forEach(ref => {
      assignRef(ref, value)
    })
  }
}

export function parseQueryString(string: string) {
  return qs.parse(string, { ignoreQueryPrefix: true })
}

export function dictOf<T, K extends string | symbol = string>(
  value: T,
  keys: K[]
): Record<K, T> {
  return keys.reduce((acc, k) => {
    acc[k] = value
    return acc
  }, {} as Record<K, T>)
}

export function normalizeUrl(url: string) {
  return /^https?:\/\//.test(url) ? url : `http://${url.trim()}`
}

export function formatBool(x: boolean): string {
  return x ? 'Yes' : 'No'
}

export function normalizeDate(date: string): string {
  const [day, time] = date.split('T')
  return `${day} | ${time.split('.')[0]}`
}

export function getCoordinates(coordinates: string): string {
  return coordinates.slice(
    coordinates.indexOf('(') + 1,
    coordinates.indexOf(')')
  )
}

export function getPointLatLng(coordinates: string) {
  const point = getCoordinates(coordinates)
  const latLng = point.split(' ')
  if (latLng.length !== 2) return
  return { lng: Number(latLng[0]), lat: Number(latLng[1]) }
}

export function transformCoordinates(coordinates: string): string {
  return `SRID=4326;POINT (${coordinates})`
}

export function getFormError(error: AxiosError): string {
  if (error.response && isObject(error.response?.data)) {
    const errArray = Object.values(error.response.data)
    if (typeof errArray[0] === 'string') return errArray[0]
    return isArray(errArray[0])
      ? errArray[0][0]
      : Object.values(errArray[0] as object)[0]
  } else {
    return CONSTANTS.ERROR_MESSAGES.sendError
  }
}

export function cutString(str: string, length: number): string {
  if (str.length < length) return str
  return str.substring(0, length) + '...'
}
