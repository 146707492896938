import React, { useCallback, useState } from 'react'

import { debounce } from 'lodash'

import { GoogleMap } from 'src/components/GoogleMap'
import { Marker, OnBoundsChandedEvent } from 'src/components/GoogleMap/types'
import { useAppDispatch, useAppSelector } from 'src/hooks/redux'

import { getReport } from '../Reports/api'
import { Report } from '../Reports/components/Report/Report'

import { MapWrapper } from './components/MapWrapper'
import { useMapPoints } from './hooks'
import { setBounds, setFilters, setSearch } from './slice'
import { FieldKey } from './types'

const MAP_DEBOUNCE_INTERVAL = 2000

export const MapPage = () => {
  const [maroToEditId, setMaroToEditId] = useState<number | null>(null)
  const dispatch = useAppDispatch()
  const mapData = useAppSelector(state => state.map)
  const userRegionCenter = useAppSelector(state => state.user.region?.center)
  const points = useMapPoints(
    mapData.mapBounds,
    mapData.zoom,
    mapData.filters,
    mapData.search
  )

  const makeGetDateRequest = (e: OnBoundsChandedEvent) => {
    dispatch(
      setBounds({
        bounds: [
          [e.north, e.east],
          [e.south, e.west],
        ],
        zoom: e.zoomLevel,
      })
    )
  }

  const changeSearchText = (val: string) => {
    dispatch(setSearch(val))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doDebounceRequest = useCallback(
    debounce(makeGetDateRequest, MAP_DEBOUNCE_INTERVAL),
    []
  )

  const setFilter = useCallback((filters: FieldKey[]) => {
    dispatch(setFilters(filters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doDebounceChangeSearchRequest = useCallback(
    debounce(changeSearchText, MAP_DEBOUNCE_INTERVAL),
    []
  )

  const loadMarkerData = useCallback(
    async (marker: Marker, cb?: (m: Marker) => void) => {
      const rep = await getReport(marker.id as number)
      marker.metadata = {
        img: rep.image ?? undefined,
        status: rep.status,
        title: rep.maro_directory?.name ?? '',
        radius: rep.damage_radius,
      }
      cb?.(marker)
    },
    []
  )

  const setMaroToEdit = useCallback((id: number | null) => {
    setMaroToEditId(id)
  }, [])

  return (
    <>
      <MapWrapper
        stats={mapData.stats}
        setFilter={setFilter}
        setSearch={doDebounceChangeSearchRequest}
      >
        <GoogleMap
          markers={points}
          onBoundsChanged={doDebounceRequest}
          onMarkerClick={loadMarkerData}
          onInfoEditClick={setMaroToEdit}
          center={userRegionCenter}
        />
      </MapWrapper>
      {maroToEditId && (
        <Report
          reportKey={maroToEditId as number}
          onClose={() => setMaroToEdit(null)}
        />
      )}
    </>
  )
}
