import React, { useEffect, useState } from 'react'

import { Checkbox } from '@mui/material'

import { FieldKey } from '../../types'

import { fields } from './constants'
import damageIcon from './damage.svg'
import searchIcon from './search.svg'
import { Field } from './types'

import styles from './MapWrapper.module.scss'

interface MapWrapperProps {
  children: React.ReactNode
  stats?: Record<FieldKey | 'total', number>
  setFilter?: (filter: FieldKey[]) => void
  setSearch?: (val: string) => void
}

export const MapWrapper = ({
  children,
  stats,
  setFilter,
  setSearch,
}: MapWrapperProps) => {
  const [formState, setFormState] = useState<Field[]>(fields)

  useEffect(() => {
    setFormState(prev => {
      return prev.map(el => ({
        ...el,
        count: stats?.[el.key] ?? el.count ?? 0,
      }))
    })
  }, [stats])

  const onCheckFilter = (checked: boolean, key: string) => {
    setFormState(prev => {
      const newState = prev.map(el => ({
        ...el,
        checked: el.key === key ? checked : el.checked,
      }))
      setFilter?.(newState.filter(el => el.checked).map(el => el.key))
      return newState
    })
  }

  const resetAll = () => {
    setFilter?.([])

    setFormState(prev =>
      prev.map(el => ({
        ...el,
        checked: false,
      }))
    )
  }

  // initially view markers related to checked default statuses
  useEffect(() => {
    setFilter?.(fields.filter(el => el.checked).map(el => el.key))
    setSearch?.('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.page_top}>
        <div className={styles.page_search_input}>
          <img src={searchIcon} className={styles.search_icon} alt="" />
          <input
            placeholder="Пошук об'єктів..."
            onInput={e =>
              setSearch?.((e.target as HTMLInputElement).value ?? '')
            }
          ></input>
        </div>
      </div>
      <div>{children}</div>
      <div className={styles.page_bottom}>
        <div className={styles.page_filters}>
          <div className={styles.page_filter_header}>
            <h5>Спеціальні позначення</h5>
            <div className={styles.page_filter_subheader}>
              <span>Відображати об'єкти</span>
              <button className={styles.page_reset_filter} onClick={resetAll}>
                сховати все
              </button>
            </div>
          </div>
          <div>
            {formState.map(el => (
              <div key={el.key} className={styles.page_filter}>
                <Checkbox
                  checked={el.checked}
                  onChange={e => onCheckFilter(e.target.checked, el.key)}
                />
                <span className={styles.page_filter_title}>{el.text}</span>
                <span>({el.count})</span>
              </div>
            ))}
            <div className={styles.damage_info}>Радіус ураження:</div>
            <img src={damageIcon} alt="" />
          </div>
        </div>
        <div className={styles.page_marker_count_wrapper}>
          <div className={styles.page_marker_count}>
            {stats?.total ?? 0} об'єктів на ділянці
          </div>
        </div>
      </div>
    </div>
  )
}
