import api from 'src/api'

import { IFilteredReports } from '../Reports/types'

import { GeoJsonPoints, ReportsParams } from './types'

export async function getMapPoints(
  params?: ReportsParams
): Promise<GeoJsonPoints> {
  const resp = await api.get<GeoJsonPoints>('report/operator/geojson', {
    params,
  })
  return resp.data
}

export async function getReports(
  params?: ReportsParams
): Promise<IFilteredReports> {
  const resp = await api.get<IFilteredReports>('report/operator', { params })
  return resp.data
}
