import { ReactNode } from 'react'

import { ThemeProvider, createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#311B92',
    },
    error: {
      main: '#FF5252',
    },
  },
})

interface IThemeProps {
  children: ReactNode
}

export const Theme = (props: IThemeProps) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
