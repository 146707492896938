import { ReportStatus } from 'src/features/Reports/types'

import { CONSTANTS } from './texts'

const { REPORT_STATUS_LABEL } = CONSTANTS

export const TABLE_ROWS_IN_PAGE = 10
export const MARO_COLORS_STATUS = {
  NEW: '#0288D1',
  CONFIRMED: '#43A047',
  DUPLICATED: '#E002CA',
  DECLINED: '#E64A19',
  NOT_ACTUALLY_FOUND: '#FF6F00',
  ACTUALLY_FOUND: '#5E35B1',
  DISPOSED: '#757575',
}

export const REPORT_STATUS: Array<{
  value: ReportStatus
  label: string
  color: string
  changeTo: ReportStatus[]
}> = [
  {
    value: 'NEW',
    label: REPORT_STATUS_LABEL.NEW,
    color: '#0288D1',
    changeTo: ['CONFIRMED', 'DECLINED', 'DUPLICATED'],
  },
  {
    value: 'CONFIRMED',
    label: REPORT_STATUS_LABEL.CONFIRMED,
    color: '#388E3C',
    changeTo: ['ACTUALLY_FOUND', 'NOT_ACTUALLY_FOUND', 'DUPLICATED'],
  },
  {
    value: 'DECLINED',
    label: REPORT_STATUS_LABEL.DECLINED,
    color: '#E002CA',
    changeTo: ['NEW'],
  },
  {
    value: 'ACTUALLY_FOUND',
    label: REPORT_STATUS_LABEL.ACTUALLY_FOUND,
    color: '#E64A19',
    changeTo: ['DISPOSED', 'DUPLICATED'],
  },
  {
    value: 'NOT_ACTUALLY_FOUND',
    label: REPORT_STATUS_LABEL.NOT_ACTUALLY_FOUND,
    color: '#FF6F00',
    changeTo: [],
  },
  {
    value: 'DISPOSED',
    label: REPORT_STATUS_LABEL.DISPOSED,
    color: '#5E35B1',
    changeTo: [],
  },
  {
    value: 'DUPLICATED',
    label: REPORT_STATUS_LABEL.DUPLICATED,
    color: '#757575',
    changeTo: ['NEW'],
  },
]
