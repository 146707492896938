import api from 'src/api'

import {
  ILogin,
  ILoginResponse,
  IRegionCurrent,
  IRegisterUser,
  IUser,
} from './types'

export async function login(params: ILogin): Promise<ILoginResponse> {
  const resp = await api.post<ILoginResponse>('login/operator', params)
  return resp.data
}

export async function getUser(): Promise<IUser> {
  const resp = await api.get<IUser>('user')
  return resp.data
}

export async function logout() {
  const resp = await api.post('logout')
  return resp.data
}

export async function registerUser(userData: IRegisterUser) {
  return await api.post('register/operator', userData)
}

export async function checkUserName(userName: ILogin['username']) {
  return await api
    .post<ILogin['username']>('check_username', {
      username: userName,
    })
    .catch(err => err.response)
}

export async function getCurrentRegion(): Promise<IRegionCurrent> {
  const resp = await api.get<IRegionCurrent>('region/current')
  return resp.data
}
