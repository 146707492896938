import api from './api'

export interface IRegions {
  pk: number
  name: string
  code: string
}

export async function getRegions(): Promise<IRegions[]> {
  const resp = await api.get<IRegions[]>('region')
  return resp.data
}
