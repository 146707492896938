import { createAsyncThunk } from '@reduxjs/toolkit'

import * as API from './api'
import { ReportsParams } from './types'

export const getMapPoints = createAsyncThunk(
  'map/fetch',
  async (params?: ReportsParams) => {
    return await API.getMapPoints(params)
  }
)
