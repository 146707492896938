import { ChangeEvent, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import CloseIcon from '@mui/icons-material/Close'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import { Button, IconButton, TextField } from '@mui/material'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import { isObject } from 'lodash'

import { MaroSelect } from 'src/components/MaroSelect'
import { Modal } from 'src/components/base/Modal'
import { CONSTANTS } from 'src/constants'
import { toggleCreateReport } from 'src/features/Reports/slice'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { cutString, getFormError, transformCoordinates } from 'src/utils'
import {
  ADDRESS,
  DESCRIPTION,
  LOCATION,
  MARO,
  PHONE,
  PHOTO,
} from 'src/utils/fields'

import { postReport } from '../../api'
import { getReports } from '../../thunks'
import { ReportFooter } from '../ReportFooter'
import { ReportView } from '../ReportView'

import {
  FIELDS_DATA,
  InitialValues,
  initialValues,
  validationSchema,
} from './lib'

import styles from './CreateReport.module.scss'

const { CONTROLS, SIDEBAR, LABELS, REPORT_MODAL, TOAST } = CONSTANTS

export const CreateReport = () => {
  const reportsFilter = useAppSelector(state => state.reports.filter)
  const dispatch = useAppDispatch()
  const inputFile = useRef<null | HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState<string | null>(null)
  const [fileSrc, setFileSrc] = useState<string | null>(null)

  const handleSubmit = async (values: InitialValues) => {
    setIsLoading(true)
    const location = transformCoordinates(values.location)
    const formData = new FormData()
    Object.entries({ ...values, location }).forEach(([key, value]) => {
      formData.append(key, isObject(value) ? JSON.stringify(value) : value)
    })
    const file = inputFile.current?.files?.[0]
    if (file && fileName) formData.append(PHOTO, file, file.name)
    try {
      await postReport(formData)
      await dispatch(getReports(reportsFilter))
      dispatch(toggleCreateReport(false))
      toast.success(TOAST.success)
    } catch (error) {
      setIsLoading(false)
      toast.error(getFormError(error as AxiosError))
    }
  }

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setFileSrc(URL.createObjectURL(file))
      setFileName(file.name ?? null)
    }
  }

  const handleClose = () => dispatch(toggleCreateReport(false))

  const handleRemoveFile = () => {
    setFileName(null)
    setFileSrc(null)
  }

  return (
    <Modal isOpen={true} onClose={handleClose} isLoading={isLoading}>
      <h2 className={styles.header}>{SIDEBAR.BUTTONS.newReport}</h2>
      <Formik<InitialValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
        }) => (
          <form className={styles.form}>
            <div className={styles.form_wrapper}>
              <div className={styles.form_block}>
                {FIELDS_DATA.map(({ name, label }) => (
                  <TextField
                    key={name}
                    label={label}
                    variant="outlined"
                    size="small"
                    type={name === PHONE ? 'number' : 'text'}
                    name={`contact_data.${name}`}
                    value={values.contact_data[name]}
                    className={styles.input}
                    onChange={handleChange}
                    error={
                      touched.contact_data?.[name] &&
                      !!errors.contact_data?.[name]
                    }
                    helperText={
                      touched.contact_data?.[name] &&
                      errors.contact_data?.[name]
                    }
                  />
                ))}
                <MaroSelect
                  value={values[MARO]}
                  className={styles.input}
                  label={`${LABELS.maroType}*`}
                  onChange={({ target }) => setFieldValue(MARO, target.value)}
                  error={touched[MARO] && !!errors[MARO]}
                  helperText={touched[MARO] && errors[MARO]}
                />
                <TextField
                  label={`${LABELS.location}*`}
                  variant="outlined"
                  size="small"
                  type="text"
                  name={LOCATION}
                  value={values[LOCATION]}
                  className={styles.input}
                  onChange={handleChange}
                  error={touched[LOCATION] && !!errors[LOCATION]}
                  helperText={
                    (touched[LOCATION] && errors[LOCATION]) ||
                    REPORT_MODAL.locationTitle
                  }
                />
                <TextField
                  label={LABELS.address}
                  variant="outlined"
                  size="small"
                  type="text"
                  multiline
                  maxRows={3}
                  name={ADDRESS}
                  value={values[ADDRESS]}
                  className={styles.input}
                  onChange={handleChange}
                  helperText={REPORT_MODAL.addressTitle}
                />
                <TextField
                  label={LABELS.description}
                  variant="outlined"
                  size="small"
                  type="text"
                  multiline
                  maxRows={3}
                  name={DESCRIPTION}
                  value={values[DESCRIPTION]}
                  className={styles.input}
                  onChange={handleChange}
                  error={touched[DESCRIPTION] && !!errors[DESCRIPTION]}
                  helperText={touched[DESCRIPTION] && errors[DESCRIPTION]}
                />
                <div className={styles.file_wrapper}>
                  <Button
                    variant="text"
                    fullWidth
                    onClick={() => inputFile.current?.click()}
                    className={styles.file_btn}
                    startIcon={<PhotoCameraIcon />}
                  >
                    {fileName ? cutString(fileName, 25) : CONTROLS.addFile}
                  </Button>
                  {fileName && (
                    <IconButton onClick={handleRemoveFile}>
                      <CloseIcon />
                    </IconButton>
                  )}
                  <input
                    type="file"
                    name={PHOTO}
                    ref={inputFile}
                    onChange={handleChangeFile}
                  />
                </div>
              </div>
              <div className={styles.map_wrapper}>
                <ReportView
                  image={fileSrc}
                  location={values[LOCATION]}
                  mapOnclick={({ lat, lng }) =>
                    setFieldValue(LOCATION, `${lng} ${lat}`)
                  }
                />
              </div>
            </div>
            <ReportFooter onSave={handleSubmit} onReset={handleClose} />
          </form>
        )}
      </Formik>
    </Modal>
  )
}
