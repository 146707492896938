import { createAsyncThunk } from '@reduxjs/toolkit'

import * as API from './api'
import { ILogin, IUser } from './types'

export const login = createAsyncThunk(
  'user/login',
  async (user: ILogin): Promise<IUser> => {
    const { key } = await API.login(user)
    const userData = await API.getUser() // TODO remove when get user data after login
    return { key, ...userData }
  }
)

export const logout = createAsyncThunk('user/logout', API.logout)

export const getCurrentUser = createAsyncThunk(
  'user/fetch',
  async () => await API.getUser()
)

export const getCurrentRegion = createAsyncThunk(
  'user/fetch-region',
  async () => await API.getCurrentRegion()
)
