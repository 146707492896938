import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { StyledEngineProvider, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { AxiosError } from 'axios'
import { Formik, FormikHelpers } from 'formik'
import { omit, pick } from 'lodash'

import { IRegions, getRegions } from 'src/api'
import { CONSTANTS } from 'src/constants'
import { IOperator } from 'src/features/User'
import { checkUserName, registerUser } from 'src/features/User/api'
import { useOnMount } from 'src/hooks'
import routes from 'src/routes'
import { getFormError } from 'src/utils'
import {
  BADGE_NUMBER,
  LOGIN,
  PASSWORD_1,
  PASSWORD_CONFIRM,
  RANK,
  REGION,
} from 'src/utils/fields'

import { ErrorMessage } from '../base'

import { RegionSelect } from './RegionSelect'
import {
  FIELDS_DATA,
  InitialValues,
  initialValues,
  validationSchema,
} from './lib'

import styles from './RegistrationForm.module.scss'

const { CONTROLS, FIELD_ERRORS } = CONSTANTS

export const RegistrationForm: React.FC = () => {
  const history = useHistory()
  const [formError, setFormError] = useState<string | null>(null)
  const [userNameError, setUserNameError] = useState<string | null>(null)
  const [regions, setRegions] = useState<IRegions[]>()
  const loginRef = useRef<HTMLInputElement | null>(null)

  useOnMount(() => {
    loginRef.current?.focus() // for check login if user has autocomplete field
    getRegions().then(data => setRegions(data))
  })

  const handleSubmit = async (values: InitialValues) => {
    const operator = pick(values, [RANK, BADGE_NUMBER, REGION]) as IOperator
    const userData = omit(values, [RANK, BADGE_NUMBER, REGION])
    try {
      await registerUser({ ...userData, operator })
      history.push(routes.registrationSuccess)
    } catch (error) {
      setFormError(getFormError(error as AxiosError))
    }
  }

  const handleBlur =
    (value: string, formikHelpers: FormikHelpers<InitialValues>) =>
    async () => {
      if (value.trim() === '') return
      const resp = await checkUserName(value)
      if (resp.status !== 200) {
        setUserNameError(FIELD_ERRORS.loginError)
        formikHelpers.setFieldError(LOGIN, FIELD_ERRORS.loginError)
      }
    }

  return (
    <div className={styles.wrapper}>
      <Formik<InitialValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, touched, errors, handleSubmit, ...rest }) => (
          <form
            className={styles.form}
            onSubmit={handleSubmit}
            onClick={() => {
              if (formError) setFormError(null)
            }}
          >
            <StyledEngineProvider injectFirst>
              {FIELDS_DATA.map(({ name, label }) => {
                const isLoginField = name === LOGIN
                const hasError = touched[name] && !!errors[name]
                const hasUserNameError = name === LOGIN && !!userNameError
                const isPassword = [PASSWORD_1, PASSWORD_CONFIRM].includes(name)

                return (
                  <TextField
                    key={name}
                    label={label}
                    variant="outlined"
                    size="small"
                    type={isPassword ? 'password' : 'text'}
                    name={name}
                    value={values[name]}
                    className={styles.input}
                    inputRef={isLoginField ? loginRef : null}
                    onChange={handleChange}
                    onBlur={
                      isLoginField ? handleBlur(values[name], rest) : undefined
                    }
                    onFocus={
                      isLoginField ? () => setUserNameError(null) : undefined
                    }
                    error={hasError || hasUserNameError}
                    helperText={
                      (hasError && errors[name]) ||
                      (hasUserNameError && userNameError)
                    }
                    margin="normal"
                  />
                )
              })}
              <RegionSelect regions={regions || []} />
              {formError && (
                <ErrorMessage className={styles.error_message}>
                  {formError}
                </ErrorMessage>
              )}
              <Button
                className={styles.btn}
                variant="contained"
                type="submit"
                size="large"
              >
                {CONTROLS.register}
              </Button>
            </StyledEngineProvider>
          </form>
        )}
      </Formik>
    </div>
  )
}
