import { useEffect, useMemo, useState } from 'react'

import { flatten } from 'lodash'

import { useAppDispatch, useAppSelector, useOnMount } from 'src/hooks'
import { normalizeDate } from 'src/utils'

import { getMaro } from './api'
import { getReports } from './thunks'
import { IGetReportParams, IMaroResponse } from './types'

export const useTableRows = (params?: IGetReportParams) => {
  const reports = useAppSelector(state => state.reports.data)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getReports(params))
  }, [dispatch, params])

  const rows = useMemo(
    () =>
      reports.results.map(report => {
        const {
          user: { first_name, middle_name, last_name, phone },
        } = report

        return {
          custom_id: report.custom_id,
          pk: report.pk,
          created_datetime: normalizeDate(report.created_datetime),
          type: report.maro_directory ? report.maro_directory.name : '-',
          address: report.address,
          applicant: `${first_name} ${middle_name} ${last_name}`,
          phone,
          status: report.status,
        }
      }),
    [reports.results]
  )
  return rows
}

export const useMaroList = () => {
  const [maroList, setMaroList] = useState<IMaroResponse[]>()
  useOnMount(() => {
    getMaro().then(data => setMaroList(data))
  })
  return flatten(maroList?.map(({ maros }) => maros))
}
