export const LOGIN = 'username'
export const PASSWORD = 'password'
export const PASSWORD_1 = 'password1'
export const PASSWORD_CONFIRM = 'password2'
export const FIRST_NAME = 'first_name'
export const MIDDLE_NAME = 'middle_name'
export const LAST_NAME = 'last_name'
export const RANK = 'rank'
export const BADGE_NUMBER = 'badge_number'
export const REGION = 'region'
export const PHONE = 'phone'
export const ADDRESS = 'address'
export const DESCRIPTION = 'description'
export const LOCATION = 'location'
export const PHOTO = 'image'
export const MARO = 'maro_directory'
