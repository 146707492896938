import { ReactNode } from 'react'

import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectProps } from '@mui/material/Select'
import clsx from 'clsx'

import { CONSTANTS } from 'src/constants'
import { useMaroList } from 'src/features/Reports/hooks'

import styles from './MaroSelect.module.scss'

const {
  LABELS: { noValue, loading },
} = CONSTANTS
interface MaroSelectProps extends SelectProps {
  helperText?: ReactNode
}
export const MaroSelect = (props: MaroSelectProps) => {
  const { label, onChange, value, className, error, helperText, ...rest } =
    props
  const maroList = useMaroList()

  if (!maroList.length) {
    return <div className={clsx(className, styles.empty)}>{loading}</div>
  }

  return (
    <FormControl className={className} error={error}>
      {label && <InputLabel id="select-label">{label}</InputLabel>}

      <Select
        value={value === '' ? noValue : value}
        labelId="select-label"
        size="small"
        {...rest}
        onChange={onChange}
        label={label}
      >
        {props.value === '' && (
          <MenuItem value={noValue}>{`(${noValue})`}</MenuItem>
        )}
        {maroList.map(({ name, pk }) => (
          <MenuItem key={pk} value={pk}>
            {name}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
