export const preventScriptInjectionText = (text: string): string => {
  if (!text) {
    return ''
  }

  return (
    text
      ?.replace(/<script/g, '&lt;script')
      .replace(/<\/script>/g, '&lt;/script&gt;')
      .replace(/<iframe/g, '&lt;iframe')
      .replace(/<\/iframe>/g, '&lt;/iframe&gt;')
      .replace(/javascript:/g, '#') ?? ''
  )
}
