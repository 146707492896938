import { CONSTANTS } from 'src/constants'
import { ReportStatus } from 'src/features/Reports/types'
import { preventScriptInjectionText } from 'src/utils/html'

import { Marker } from './types'

export const generateInfoTooltipContent = (
  marker: Marker,
  onEditClick: () => void
): HTMLDivElement => {
  const { REPORT_STATUS_LABEL } = CONSTANTS

  const infoWindowContainer$ = document.createElement('div')
  infoWindowContainer$.classList.add('info_map_window')

  const header$ = document.createElement('div')
  header$.classList.add('info_map_header')
  const status$ = document.createElement('div')
  status$.classList.add('info_map_object_status')
  status$.innerText =
    REPORT_STATUS_LABEL[marker.metadata?.status as ReportStatus] ??
    'Статус не відомий'
  status$.style.borderColor = marker.metadata?.color ?? ''
  status$.style.color = marker.metadata?.color ?? ''
  const editBtn$ = document.createElement('div')
  editBtn$.classList.add('info_map_edit_btn')
  editBtn$.innerHTML = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2656 3.85938L13.7422 5.38281L10.6172 2.25781L12.1406 0.734375C12.2969 0.578125 12.4922 0.5 12.7266 0.5C12.9609 0.5 13.1562 0.578125 13.3125 0.734375L15.2656 2.6875C15.4219 2.84375 15.5 3.03906 15.5 3.27344C15.5 3.50781 15.4219 3.70312 15.2656 3.85938ZM0.5 12.375L9.71875 3.15625L12.8438 6.28125L3.625 15.5H0.5V12.375Z" fill="#757575"/>
    </svg>`

  editBtn$.onclick = onEditClick

  header$.appendChild(status$)
  header$.appendChild(editBtn$)

  const body$ = document.createElement('div')
  body$.innerHTML = preventScriptInjectionText(`
    <div>
      <img src="${marker.metadata?.img}" alt="" />
      ${
        (!marker.metadata?.img &&
          `
          <div class="info_map_no_photo">
            <svg
              class="MuiSvgIcon-root
              MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PhotoCameraIcon"><circle cx="12" cy="12" r="3.2"></circle><path d="M9 2 7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path></svg>
              <div>Немає фото</div>
            </div>
          `) ||
        ''
      }
        <div class="info_map_window_footer">
          <b>${marker.metadata?.title}</b>
          <div>${marker.metadata?.radius || 0}м</div>
        </div>
      </div>
    `)

  infoWindowContainer$.appendChild(header$)
  infoWindowContainer$.appendChild(body$)
  return infoWindowContainer$
}

export const generateClusterInfoTooltip = (marker: Marker) => {
  const infoWindowContainer$ = document.createElement('div')
  infoWindowContainer$.classList.add('info_map_window')

  const header$ = document.createElement('div')
  header$.classList.add('info_map_header')
  const status$ = document.createElement('div')
  status$.classList.add('info_map_object_status')
  status$.innerText = marker.metadata?.status ?? 'Статус не відомий'
  status$.style.borderColor = marker.metadata?.color ?? ''
  status$.style.color = marker.metadata?.color ?? ''
  const editBtn$ = document.createElement('div')
  editBtn$.classList.add('info_map_edit_btn')
  editBtn$.innerHTML = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2656 3.85938L13.7422 5.38281L10.6172 2.25781L12.1406 0.734375C12.2969 0.578125 12.4922 0.5 12.7266 0.5C12.9609 0.5 13.1562 0.578125 13.3125 0.734375L15.2656 2.6875C15.4219 2.84375 15.5 3.03906 15.5 3.27344C15.5 3.50781 15.4219 3.70312 15.2656 3.85938ZM0.5 12.375L9.71875 3.15625L12.8438 6.28125L3.625 15.5H0.5V12.375Z" fill="#757575"/>
    </svg>`

  editBtn$.onclick = () => {
    // TODO edit click here
  }

  header$.appendChild(status$)
  header$.appendChild(editBtn$)

  const body$ = document.createElement('div')
  body$.innerHTML = preventScriptInjectionText(`
    <div>
      <img src="${marker.metadata?.img}" alt="" />
        <div class="info_map_window_footer">
          <b>${marker.metadata?.title}</b>
          <div>${marker.metadata?.radius || 0}м</div>
        </div>
      </div>
    `)

  infoWindowContainer$.appendChild(header$)
  infoWindowContainer$.appendChild(body$)
  return infoWindowContainer$
}
