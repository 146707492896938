import api from 'src/api'

import {
  IFilteredReports,
  IGetReportParams,
  IMaroResponse,
  IReportResponse,
} from './types'

export async function getReports(
  params?: IGetReportParams
): Promise<IFilteredReports> {
  const resp = await api.get<IFilteredReports>('report/operator', { params })
  return resp.data
}

export async function getReport(id: number): Promise<IReportResponse> {
  const resp = await api.get<IReportResponse>(`report/${id}/operator`)
  return resp.data
}

export async function patchReport(
  pk: number,
  payload: Record<string, number | string>
): Promise<IReportResponse> {
  const resp = await api.patch<IReportResponse>(
    `report/${pk}/operator`,
    payload
  )
  return resp.data
}

export async function getMaro(): Promise<IMaroResponse[]> {
  const resp = await api.get<IMaroResponse[]>('maro')
  return resp.data
}

export async function postReport(data: FormData) {
  const resp = await api.post<FormData>('report/create/operator', data)
  return resp.data
}
