import { HeadCell } from 'src/features/Reports/types'

export const enhancedTableHeadCells: HeadCell[] = [
  {
    id: 'pk',
    label: 'Запит',
  },
  {
    id: 'created_datetime',
    label: 'Дата',
  },
  {
    id: 'type',
    label: 'Тип',
  },
  {
    id: 'address',
    label: 'Адреса',
  },
  {
    id: 'applicant',
    label: 'Заявник',
  },
  {
    id: 'phone',
    label: 'Телефон',
  },
  {
    id: 'status',
    label: 'Статус',
  },
]
