import React from 'react'
import { Link } from 'react-router-dom'

import { CONSTANTS } from 'src/constants'
import logo from 'src/logo.svg'
import routes from 'src/routes'

const { REGISTRATION_SUCCESS } = CONSTANTS
export const RegistrationSuccess: React.FC = () => (
  <main style={{ display: 'flex', height: '70vh' }}>
    <div style={{ margin: 'auto', textAlign: 'center' }}>
      <img style={{ margin: 50 }} src={logo} width={180} alt="logo" />
      <h1 style={{ color: '#10054D' }}>{REGISTRATION_SUCCESS.header}</h1>
      <h2 style={{ marginTop: 20, marginBottom: 50, color: '#757575' }}>
        {REGISTRATION_SUCCESS.checkText}
      </h2>
      <Link to={routes.login} style={{ fontSize: '1.5rem' }}>
        {REGISTRATION_SUCCESS.loginLink}
      </Link>
    </div>
  </main>
)
