import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { DEFAULT_ZOOM_LEVEL, ZOOM_LEVEL_RADIUS } from 'src/constants/map'
import { addAsyncThunkReducers } from 'src/utils'

import * as reportsThunks from '../Reports/thunks'

import * as thunks from './thunks'
import { FieldKey, GeoJsonPoints } from './types'

export type MapBounds = [[number, number], [number, number]]

interface InitialState {
  loading: boolean
  error: string | undefined
  stats: Record<FieldKey | 'total', number>
  filters: FieldKey[]
  markers: GeoJsonPoints
  mapBounds: MapBounds | undefined
  zoom: number
  search: string
}

const initialState: InitialState = {
  loading: false,
  error: undefined,
  stats: {
    ACTUALLY_FOUND: 0,
    CONFIRMED: 0,
    DECLINED: 0,
    DISPOSED: 0,
    DUPLICATED: 0,
    NEW: 0,
    NOT_ACTUALLY_FOUND: 0,
    total: 0,
  },
  markers: {} as GeoJsonPoints,
  filters: [],
  mapBounds: undefined,
  zoom: ZOOM_LEVEL_RADIUS[DEFAULT_ZOOM_LEVEL],
  search: '',
}

export const MapPageSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setFilters(state, { payload }: PayloadAction<FieldKey[]>) {
      state.filters = payload
    },
    setBounds(
      state,
      { payload }: PayloadAction<{ bounds: MapBounds; zoom: number }>
    ) {
      state.mapBounds = payload.bounds
      state.zoom = ZOOM_LEVEL_RADIUS[payload.zoom]
    },
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload
    },
    clearMarkers(state) {
      state.markers = { ...initialState.markers }
    },
  },
  extraReducers: builder => {
    addAsyncThunkReducers(builder, thunks.getMapPoints, (state, data) => {
      state.markers = data
    })
    addAsyncThunkReducers(builder, reportsThunks.getReports, (state, data) => {
      state.stats = {
        ACTUALLY_FOUND: data.ACTUALLY_FOUND,
        CONFIRMED: data.CONFIRMED,
        DECLINED: data.DECLINED,
        DISPOSED: data.DISPOSED,
        DUPLICATED: data.DUPLICATED,
        NEW: data.NEW,
        NOT_ACTUALLY_FOUND: data.NOT_ACTUALLY_FOUND,
        total: data.count,
      }
    })
  },
})

export const { setFilters, setBounds, clearMarkers, setSearch } =
  MapPageSlice.actions
