import * as Yup from 'yup'

import { CONSTANTS, MAX_CHARACTERS, PASSWORD_REG_EXP } from 'src/constants'
import * as FIELD_NAMES from 'src/utils/fields'

const { LABELS, FIELD_ERRORS } = CONSTANTS

export const FIELDS_DATA: Array<{ label: string; name: keyof InitialValues }> =
  [
    { label: LABELS.login, name: FIELD_NAMES.LOGIN },
    { label: LABELS.password, name: FIELD_NAMES.PASSWORD_1 },
    { label: LABELS.passwordConfirm, name: FIELD_NAMES.PASSWORD_CONFIRM },
    { label: LABELS.name, name: FIELD_NAMES.FIRST_NAME },
    { label: LABELS.middleName, name: FIELD_NAMES.MIDDLE_NAME },
    { label: LABELS.lastName, name: FIELD_NAMES.LAST_NAME },
    { label: LABELS.rank, name: FIELD_NAMES.RANK },
    { label: LABELS.badgeNumber, name: FIELD_NAMES.BADGE_NUMBER },
  ]

export interface InitialValues {
  [FIELD_NAMES.LOGIN]: string
  [FIELD_NAMES.PASSWORD_1]: string
  [FIELD_NAMES.PASSWORD_CONFIRM]: string
  [FIELD_NAMES.FIRST_NAME]: string
  [FIELD_NAMES.MIDDLE_NAME]: string
  [FIELD_NAMES.LAST_NAME]: string
  [FIELD_NAMES.RANK]: string
  [FIELD_NAMES.BADGE_NUMBER]: string
  [FIELD_NAMES.REGION]: string | number
}

export const initialValues: InitialValues = {
  [FIELD_NAMES.LOGIN]: '',
  [FIELD_NAMES.PASSWORD_1]: '',
  [FIELD_NAMES.PASSWORD_CONFIRM]: '',
  [FIELD_NAMES.FIRST_NAME]: '',
  [FIELD_NAMES.MIDDLE_NAME]: '',
  [FIELD_NAMES.LAST_NAME]: '',
  [FIELD_NAMES.RANK]: '',
  [FIELD_NAMES.BADGE_NUMBER]: '',
  [FIELD_NAMES.REGION]: '',
}

export const validationSchema = Yup.object({
  [FIELD_NAMES.LOGIN]: Yup.string()
    .trim()
    .max(MAX_CHARACTERS, FIELD_ERRORS.maxCharacters)
    .required(FIELD_ERRORS.required),
  [FIELD_NAMES.PASSWORD_1]: Yup.string()
    .required(FIELD_ERRORS.required)
    .matches(PASSWORD_REG_EXP, FIELD_ERRORS.password),
  [FIELD_NAMES.PASSWORD_CONFIRM]: Yup.string()
    .required(FIELD_ERRORS.required)
    .oneOf(
      [Yup.ref(FIELD_NAMES.PASSWORD_1), null],
      FIELD_ERRORS.passwordConfirm
    ),
  [FIELD_NAMES.FIRST_NAME]: Yup.string()
    .trim()
    .max(MAX_CHARACTERS, FIELD_ERRORS.maxCharacters)
    .required(FIELD_ERRORS.required),
  [FIELD_NAMES.MIDDLE_NAME]: Yup.string()
    .trim()
    .max(MAX_CHARACTERS, FIELD_ERRORS.maxCharacters)
    .required(FIELD_ERRORS.required),
  [FIELD_NAMES.LAST_NAME]: Yup.string()
    .trim()
    .max(MAX_CHARACTERS, FIELD_ERRORS.maxCharacters)
    .required(FIELD_ERRORS.required),
  [FIELD_NAMES.RANK]: Yup.string()
    .trim()
    .max(MAX_CHARACTERS, FIELD_ERRORS.maxCharacters)
    .required(FIELD_ERRORS.required),
  [FIELD_NAMES.BADGE_NUMBER]: Yup.string()
    .trim()
    .max(MAX_CHARACTERS, FIELD_ERRORS.maxCharacters)
    .required(FIELD_ERRORS.required),
  [FIELD_NAMES.REGION]: Yup.string().required(FIELD_ERRORS.required),
})
