import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { useField } from 'formik'

import { IRegions } from 'src/api'
import { CONSTANTS } from 'src/constants'
import { REGION } from 'src/utils/fields'

const { LABELS } = CONSTANTS

export const RegionSelect = ({ regions }: { regions: IRegions[] }) => {
  const [field, meta] = useField(REGION)
  const { touched, error } = meta

  return (
    <FormControl
      fullWidth
      error={!!(touched && error)}
      size="small"
      margin="normal"
    >
      <InputLabel id="select-label">{LABELS.region}</InputLabel>
      <Select
        labelId="select-label"
        value={meta.value}
        label={LABELS.region}
        onChange={field.onChange}
        name={REGION}
        error={meta.touched && Boolean(meta.error)}
        size="small"
      >
        {regions.map(({ pk, name }) => (
          <MenuItem key={pk} value={pk}>
            {name}
          </MenuItem>
        ))}
      </Select>
      {touched && error && (
        <FormHelperText>{LABELS.selectRegion}</FormHelperText>
      )}
    </FormControl>
  )
}
