import { createSlice } from '@reduxjs/toolkit'

import { addAsyncThunkReducers } from 'src/utils'

import * as thunks from './thunks'
import { IUser, IUserState } from './types'

const initialState: IUserState = {
  loading: false,
  error: undefined,
  data: undefined,
  region: undefined,
}

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined
    },
  },
  extraReducers: builder => {
    addAsyncThunkReducers(
      builder,
      thunks.login,
      (state: IUserState, user: IUser) => {
        state.data = user
      }
    )
    addAsyncThunkReducers(builder, thunks.logout, state => {
      state.data = undefined
      state.region = undefined
    })
    addAsyncThunkReducers(builder, thunks.getCurrentUser, (state, userData) => {
      state.data = { ...state.data, ...userData }
    })
    addAsyncThunkReducers(builder, thunks.getCurrentRegion, (state, data) => {
      state.region = data
    })
  },
})

export const { clearError } = UserSlice.actions
