import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { StyledEngineProvider } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Formik } from 'formik'

import ErrorMessage from 'src/components/base/ErrorMessage'
import { CONSTANTS } from 'src/constants'
import { getCurrentRegion, login } from 'src/features/User'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import routes from 'src/routes'
import { LOGIN, PASSWORD } from 'src/utils/fields'

import {
  FIELD_PROPS,
  InitialValues,
  initialValues,
  validationSchema,
} from './lib'

import styles from './Login.module.scss'

const { CONTROLS, LABELS, ERROR_MESSAGES } = CONSTANTS

export const Login: React.FC = () => {
  const user = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const history = useHistory()

  return (
    <div className={styles.wrapper}>
      <Formik<InitialValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async values => {
          await dispatch(login(values)).unwrap()
          dispatch(getCurrentRegion())
          history.push(routes.home)
        }}
      >
        {({ values, handleChange, touched, errors, handleSubmit }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <StyledEngineProvider injectFirst>
              <TextField
                label={LABELS.login}
                name={LOGIN}
                value={values.username}
                onChange={handleChange}
                error={touched.username && Boolean(errors.username)}
                helperText={touched.username && errors.username}
                {...FIELD_PROPS}
              />
              <TextField
                label={LABELS.password}
                name="password"
                value={values.password}
                type={PASSWORD}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                {...FIELD_PROPS}
              />
              {user.error && (
                <ErrorMessage className={styles.error_message}>
                  {ERROR_MESSAGES.wrongUserData}
                </ErrorMessage>
              )}
              <Button
                className={styles.btn}
                variant="contained"
                type="submit"
                size="large"
                color="success"
              >
                {CONTROLS.login}
              </Button>
              <Link to={routes.registration} className={styles.btn}>
                <Button
                  variant="contained"
                  type="button"
                  size="large"
                  fullWidth
                >
                  {CONTROLS.register}
                </Button>
              </Link>
            </StyledEngineProvider>
          </form>
        )}
      </Formik>
    </div>
  )
}
