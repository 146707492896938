import { useEffect, useMemo } from 'react'

import { Marker } from 'src/components/GoogleMap/types'
import { useAppDispatch, useAppSelector } from 'src/hooks'

import { getReports } from '../Reports/thunks'
import { ReportStatus } from '../Reports/types'

import { MapBounds, clearMarkers } from './slice'
import { getMapPoints } from './thunks'

export const useMapPoints = (
  bounds: MapBounds | undefined,
  zoom: number,
  filters: ReportStatus[],
  search: string
) => {
  const markerGeoJSON = useAppSelector(state => state.map.markers)

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!bounds) {
      return
    }

    if (filters?.length === 0) {
      dispatch(clearMarkers())
    } else {
      dispatch(
        getMapPoints({
          points: `POINT (${bounds[1][1]} ${bounds[1][0]}) POINT (${bounds[0][1]} ${bounds[0][0]})`,
          zoomLvl: zoom,
          status_choices: filters || undefined,
          search,
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, bounds, search])

  useEffect(() => {
    if (!bounds) {
      return
    }

    dispatch(
      getReports({
        points: `POINT (${bounds[1][1]} ${bounds[1][0]}) POINT (${bounds[0][1]} ${bounds[0][0]})`,
        limit: 1,
        offset: 0,
        search,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds, search])

  const markers = useMemo(
    () =>
      (markerGeoJSON.features ?? []).map(m => {
        const newMarker: Marker = {
          id: m.properties.id,
          lng: m.geometry.coordinates[0],
          lat: m.geometry.coordinates[1],
        }

        if (m.properties.count && m.properties.count >= 1) {
          newMarker.clusterMetadata = {
            pointsCount: m.properties.count,
          }
          newMarker.label = `${m.properties.count} об'єктів різних статусів`
        } else {
          newMarker.metadata = {
            radius: m.properties.damage_radius,
            withInfoWindow: true,
          }
        }
        return newMarker
      }),
    [markerGeoJSON]
  )
  return markers
}
