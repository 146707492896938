export const ZOOM_LEVEL_RADIUS: Record<number, number> = {
  2: 10,
  3: 3,
  4: 1.2,
  5: 0.9,
  6: 0.8,
  7: 0.3,
  8: 0.2,
  9: 0.1,
  10: 0.05,
  11: 0.04,
  12: 0.03,
  13: 0.02,
  14: 0.01,
}

export const DEFAULT_ZOOM_LEVEL = 10

export const MAP_DEFAULT_CENTER = {
  lat: 50.45466,
  lng: 30.5238,
}
