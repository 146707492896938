import React, { SyntheticEvent, useState } from 'react'

// import FilterListIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import { debounce } from 'lodash'

import { CONSTANTS } from 'src/constants'
import { useAppDispatch, useAppSelector } from 'src/hooks'

import { setFilter, setPage } from '../../slice'
import { FilterProps, FilterTabValue, IFilteredReports } from '../../types'

import styles from './Filter.module.scss'

export const DEFAULT_TAB: FilterTabValue = 'TOTAL'

const {
  LABELS,
  REPORTS_PAGE: { FILTER_TABS, excelExport },
} = CONSTANTS

export const Filter = ({ onExportExcelClick }: FilterProps) => {
  const dispatch = useAppDispatch()
  const reports = useAppSelector(state => state.reports)
  const tabNames = Object.keys(FILTER_TABS)
  const [tabValue, setTabValue] = useState<FilterTabValue>(DEFAULT_TAB)

  const handleChangeTab = (event: SyntheticEvent, newValue: FilterTabValue) => {
    setTabValue(newValue)
    dispatch(setPage(0))
    dispatch(setFilter({ status: newValue, offset: 0 }))
  }

  const debouncedSearch = debounce(async value => {
    await dispatch(setFilter({ search: value, offset: 0 }))
  }, 200)

  return (
    <>
      <div className={styles.flex_wrapper}>
        <div className={styles.search}>
          <IconButton className={styles.search__btn} type="submit">
            <SearchIcon />
          </IconButton>
          <TextField
            placeholder={LABELS.search}
            size="small"
            onChange={e => debouncedSearch(e.target.value)}
          />
        </div>
        {/* {no filters yet} */}
        {/* <div className={styles.filter}>
          <IconButton color="primary">
            <FilterListIcon />
          </IconButton>
          <span className={styles.filter__text}>{LABELS.filter}</span>
        </div> */}
        <div className={styles.export_btn}>
          <Button variant="outlined" onClick={onExportExcelClick}>
            {excelExport}
          </Button>
        </div>
      </div>
      <Box className={styles.tab_wrapper}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
        >
          {tabNames.map(name => (
            <Tab
              key={name}
              value={name}
              label={`${FILTER_TABS[name as keyof typeof FILTER_TABS]} ${
                reports.data[name as keyof IFilteredReports]
              }`}
            />
          ))}
        </Tabs>
      </Box>
    </>
  )
}
