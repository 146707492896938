import { Field } from './types'

export const fields: Field[] = [
  {
    key: 'NEW',
    text: 'Нові',
    checked: true,
  },
  {
    key: 'CONFIRMED',
    text: 'Підтерджені',
    checked: true,
  },
  {
    key: 'DUPLICATED',
    text: 'Дублікат',
    checked: true,
  },
  {
    key: 'DECLINED',
    text: 'Відхилені',
    checked: true,
  },
  {
    key: 'NOT_ACTUALLY_FOUND',
    text: 'Не знайдені',
    checked: true,
  },
  {
    key: 'ACTUALLY_FOUND',
    text: 'Знайдені',
    checked: true,
  },
  {
    key: 'DISPOSED',
    text: 'Знешкодженні',
    checked: true,
  },
]
