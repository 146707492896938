import { TextFieldProps } from '@mui/material'
import * as Yup from 'yup'

import { CONSTANTS, PASSWORD_REG_EXP } from 'src/constants'
import { LOGIN, PASSWORD } from 'src/utils/fields'

const {
  FIELD_ERRORS: { required, password },
} = CONSTANTS

export interface InitialValues {
  [LOGIN]: string
  [PASSWORD]: string
}

export const initialValues: InitialValues = { [LOGIN]: '', [PASSWORD]: '' }
export const validationSchema = Yup.object({
  [LOGIN]: Yup.string().required(required),
  [PASSWORD]: Yup.string()
    .required(required)
    .matches(PASSWORD_REG_EXP, password),
})

export const FIELD_PROPS: TextFieldProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}
