import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { omit } from 'lodash'

import { TABLE_ROWS_IN_PAGE } from 'src/constants'
import { addAsyncThunkReducers } from 'src/utils'

import { DEFAULT_TAB } from './components/Filter'
import * as thunks from './thunks'
import { IFilteredReports, IGetReportParams, IReportResponse } from './types'

interface InitialState {
  loading: boolean
  error: string | undefined
  data: IFilteredReports
  filter: IGetReportParams
  selectedReport: IReportResponse | null
  isShowCreateReport: boolean
  page: number
}

const filterInitialState: IGetReportParams = {
  limit: TABLE_ROWS_IN_PAGE,
  offset: 0,
  search: '',
}

const initialState: InitialState = {
  loading: false,
  error: undefined,
  data: {
    ACTUALLY_FOUND: 0,
    CONFIRMED: 0,
    DECLINED: 0,
    DISPOSED: 0,
    DUPLICATED: 0,
    NEW: 0,
    NOT_ACTUALLY_FOUND: 0,
    TOTAL: 0,
    count: 0,
    results: [],
  },
  filter: filterInitialState,
  selectedReport: null,
  isShowCreateReport: false,
  page: 0,
}

export const ReportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setFilter(state, { payload }: PayloadAction<IGetReportParams>) {
      const filter = { ...state.filter, ...payload }
      state.filter =
        payload.status === DEFAULT_TAB ? omit(filter, 'status') : filter
    },
    setPage(state, { payload }: PayloadAction<number>) {
      state.page = payload
    },
    clearSelectedReport(state) {
      state.selectedReport = null
    },
    toggleCreateReport(state, action: PayloadAction<boolean>) {
      state.isShowCreateReport = action.payload
    },
  },
  extraReducers: builder => {
    addAsyncThunkReducers(builder, thunks.getReports, (state, data) => {
      state.data = data
    })
    addAsyncThunkReducers(builder, thunks.getReport, (state, report) => {
      state.selectedReport = report
    })
    addAsyncThunkReducers(builder, thunks.patchReport, (state, data) => {
      if (data) state.data = data
    })
  },
})

export const { setFilter, clearSelectedReport, toggleCreateReport, setPage } =
  ReportsSlice.actions
