import React, { useCallback, useState } from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import { CONSTANTS, TABLE_ROWS_IN_PAGE } from 'src/constants'
import { enhancedTableHeadCells as headCells } from 'src/constants/table'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { exportToExcel } from 'src/utils/excel'

import { EnhancedTableHead } from './components/EnhancedTableHead'
import { Filter } from './components/Filter'
import { Report } from './components/Report'
import { useTableRows } from './hooks'
import { setFilter, setPage } from './slice'
import { ITableData, Order } from './types'

const { REPORT_STATUS_LABEL } = CONSTANTS

export const ReportsTable: React.FC = () => {
  const dispatch = useAppDispatch()
  const reports = useAppSelector(state => state.reports)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof ITableData>('pk')
  const [selectedReport, setSelectedReport] = useState<number | null>(null)

  const rows = useTableRows(reports.filter)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ITableData
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setPage(newPage))
    dispatch(setFilter({ offset: (reports.filter.limit ?? 0) * newPage }))
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setPage(0))
    dispatch(setFilter({ limit: +event.target.value, offset: 0 }))
  }

  const saveExcelFile = useCallback(() => {
    const data = rows.map(el => ({
      [headCells[0].label]: el.custom_id,
      [headCells[1].label]: el.created_datetime,
      [headCells[2].label]: el.type,
      [headCells[3].label]: el.address,
      [headCells[4].label]: el.applicant,
      [headCells[5].label]: el.phone,
      [headCells[6].label]: REPORT_STATUS_LABEL[el.status],
    }))
    exportToExcel(data, 'reports')
  }, [rows])

  return (
    <Box sx={{ width: '100%', minWidth: 1024, marginBottom: '24px' }}>
      <Filter onExportExcelClick={saveExcelFile} />
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows
                .slice()
                .sort(getComparator(order, orderBy))
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.pk}
                      onClick={() => setSelectedReport(row.pk)}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {/* TODO: remove row.pk when it fix on BE */}
                        {row.custom_id ?? row.pk}
                      </TableCell>
                      <TableCell>{row.created_datetime}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell>{row.applicant}</TableCell>
                      <TableCell>{row.phone}</TableCell>
                      <TableCell>{REPORT_STATUS_LABEL[row.status]}</TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={reports.data.count ?? 0}
          rowsPerPage={reports.filter.limit ?? TABLE_ROWS_IN_PAGE}
          page={reports.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {selectedReport && (
        <Report reportKey={selectedReport} onClose={setSelectedReport} />
      )}
    </Box>
  )
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof ITableData>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
