import React, { useMemo, useState } from 'react'

import Button from '@mui/material/Button'

import { CONSTANTS } from 'src/constants'

const { CONTROLS } = CONSTANTS

interface ShowMoreTextProps {
  text: string
  className?: string
}

export const ShowMoreText = ({ text, className }: ShowMoreTextProps) => {
  const [showFullText, setShowFullText] = useState(false)

  const shortText = useMemo(() => text.slice(0, 160) ?? '', [text])

  return (
    <>
      <p className={className}>
        {showFullText || text.length <= shortText.length
          ? text
          : `${shortText}...`}
      </p>
      {text.length > shortText.length && (
        <Button onClick={() => setShowFullText(!showFullText)}>
          {showFullText ? CONTROLS.showLess : CONTROLS.showMore}
        </Button>
      )}
    </>
  )
}
