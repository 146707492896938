import React from 'react'

import { Page } from 'src/components/Page'
import { CONSTANTS } from 'src/constants'
import { ReportsTable } from 'src/features/Reports'

export const Reports: React.FC = () => {
  return (
    <Page center={true} title={CONSTANTS.REPORTS_PAGE.mainHeader}>
      <ReportsTable />
    </Page>
  )
}
