import React from 'react'

import LoopIcon from '@mui/icons-material/Loop'
import clsx from 'clsx'

import { ILoaderProps } from './types'

import styles from './styles.module.scss'

export default function Loader(props: ILoaderProps) {
  const { center = false, className, fontSize = 'large', ...rest } = props
  return (
    <LoopIcon
      className={clsx(className, {
        [styles.center]: center,
      })}
      fontSize={fontSize}
      {...rest}
    />
  )
}
