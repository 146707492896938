import React, { MouseEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LogoutIcon from '@mui/icons-material/Logout'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { CONSTANTS } from 'src/constants'
import { logout } from 'src/features/User'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import logo from 'src/logo.svg'
import routes from 'src/routes'

import styles from './styles.module.scss'

const { CONTROLS, HEADER } = CONSTANTS

export const Header = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user.data)
  const userRegion = useAppSelector(state => state.user.region?.name)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = async () => {
    await dispatch(logout())
    history.push(routes.login)
  }

  return (
    <header className={styles.header}>
      <div className={styles.flex}>
        <img src={logo} alt="logo" />
        <h1 className={styles.title}>
          <span>{HEADER.dsns}</span>
          <span>{HEADER.clearMine}</span>
        </h1>
      </div>
      <div className={styles.flex}>
        <div className={styles.profile}>
          <Button
            aria-controls={open ? 'customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="text"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {CONTROLS.profile}
          </Button>
          <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem>{`${user?.first_name} ${user?.last_name}`}</MenuItem>
            {userRegion && (
              <MenuItem>
                <span className={styles.profile_gray}>
                  {`${HEADER.region}:`}&nbsp;
                </span>
                {userRegion}
              </MenuItem>
            )}
            <MenuItem onClick={handleClose}>
              <Button
                onClick={handleLogout}
                variant="text"
                startIcon={<LogoutIcon />}
                className={styles.exit}
                fullWidth
              >
                {CONTROLS.logout}
              </Button>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  )
}
