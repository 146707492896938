import { Coords } from 'google-map-react'
import React, { useEffect, useState } from 'react'

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

import { GoogleMap } from 'src/components/GoogleMap'
import { CONSTANTS } from 'src/constants'
import { getPointLatLng } from 'src/utils'

import styles from './ReportView.module.scss'

interface ReportViewProps {
  image: string | null
  location: string
  mapOnclick?: (coords: Coords) => void
}
const { REPORT_MODAL } = CONSTANTS

export const ReportView = (props: ReportViewProps) => {
  const { image, location, mapOnclick } = props
  const [isShowMap, setIsShowMap] = useState(false)
  const point = getPointLatLng(location)
  useEffect(() => {
    image ? setIsShowMap(false) : setIsShowMap(true)
  }, [image])

  return (
    <div className={styles.view}>
      <ButtonGroup variant="contained" className={styles.btn_group}>
        <Button
          variant={isShowMap ? 'outlined' : 'contained'}
          onClick={() => setIsShowMap(false)}
        >
          {REPORT_MODAL.photo}
        </Button>
        <Button
          variant={isShowMap ? 'contained' : 'outlined'}
          onClick={() => setIsShowMap(true)}
        >
          {REPORT_MODAL.map}
        </Button>
      </ButtonGroup>
      {image && !isShowMap && <img src={image} alt={REPORT_MODAL.imgAlt} />}
      {!image && !isShowMap && (
        <div className={styles.image_replacement}>
          <div className={styles.photo_wrap}>
            <div className={styles.photo}>
              <PhotoCameraIcon />
            </div>
            <p className={styles.bold}>{REPORT_MODAL.noPhoto}</p>
            <p>{REPORT_MODAL.noPhotoDescription}</p>
          </div>
        </div>
      )}
      {isShowMap && (
        <div>
          <GoogleMap
            markers={point ? [point] : []}
            center={point}
            onClick={mapOnclick}
          />
        </div>
      )}
    </div>
  )
}
