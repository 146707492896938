import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { Description } from '@mui/icons-material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CancelIcon from '@mui/icons-material/Cancel'
import CategoryIcon from '@mui/icons-material/Category'
import EditIcon from '@mui/icons-material/Edit'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'
import IconButton from '@mui/material/IconButton'
import { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { isEqual } from 'lodash'

import { MaroSelect } from 'src/components/MaroSelect'
import { ShowMoreText } from 'src/components/ShowMoreText'
import { StatusButton } from 'src/components/StatusButton'
import { Modal } from 'src/components/base/Modal'
import { CONSTANTS } from 'src/constants'
import { useAppDispatch, useAppSelector, useOnMount } from 'src/hooks'
import { getCoordinates, normalizeDate, transformCoordinates } from 'src/utils'

import { clearSelectedReport } from '../../slice'
import { getReport, patchReport } from '../../thunks'
import { IReportResponse, ReportStatus } from '../../types'
import { ReportFooter } from '../ReportFooter'
import { ReportView } from '../ReportView'

import { IReportProps, IReportValues, reportValuesDefault } from './lib'

import styles from './Report.module.scss'

const { LABELS, REPORT_MODAL } = CONSTANTS

export const Report = ({ reportKey, onClose }: IReportProps) => {
  const dispatch = useAppDispatch()
  const report = useAppSelector(state => state.reports.selectedReport)
  const [isEditLocation, setIsEditLocation] = useState(false)
  const [prevReportValues, setPrevReportValues] = useState<IReportValues>()
  const [statusValue, setStatusValue] = useState<ReportStatus>('NEW')
  const [reportValues, setReportValues] =
    useState<IReportValues>(reportValuesDefault)

  const handleChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    setReportValues({ ...reportValues, [e.currentTarget.name]: e.target.value })
  }

  const handleChangeSelect = (e: SelectChangeEvent<unknown>) => {
    setReportValues({ ...reportValues, [e.target.name]: e.target.value })
  }

  const handleChangeStatus = (status: ReportStatus) => {
    setStatusValue(status)
    dispatch(patchReport({ pk: reportKey, payload: { status } }))
  }

  const handleSendData = async () => {
    if (isEqual(prevReportValues, reportValues)) {
      onClose(null)
    } else {
      const resp = await dispatch(
        patchReport({
          pk: reportKey,
          payload: {
            ...reportValues,
            location: transformCoordinates(reportValues.location),
          },
        })
      ).unwrap()
      if (resp) onClose(null)
    }
  }

  const handleResetData = () => {
    if (prevReportValues) setReportValues(prevReportValues)
  }

  useOnMount(() => {
    dispatch(getReport(reportKey)).then(data => {
      const report = data.payload as IReportResponse
      const values = {
        location: getCoordinates(report.location),
        maro_directory: report.maro_directory?.pk ?? '',
      }
      setStatusValue(report.status)
      setReportValues(values)
      setPrevReportValues(values)
    })
  })

  useEffect(() => {
    return () => {
      dispatch(clearSelectedReport())
    }
  }, [dispatch])

  const shortLocationString = useMemo(() => {
    if (!reportValues.location) return ''
    const loc = reportValues.location.split(' ')
    if (loc.length < 2) return reportValues.location
    return loc.map(el => Number(el ?? 0).toFixed(6)).join(' ')
  }, [reportValues.location])

  return (
    <Modal
      isOpen={!!reportKey}
      onClose={() => onClose(null)}
      isLoading={!report}
    >
      <header className={styles.header}>
        <h3
          className={styles.title}
        >{`${REPORT_MODAL.request} ${report?.custom_id}`}</h3>
        <StatusButton
          defaultValue={statusValue}
          onChange={handleChangeStatus}
        />
      </header>
      <div className={styles.modal_main}>
        <div className={styles.description}>
          <div className={styles.description_item}>
            <CalendarTodayIcon className={styles.description_icon} />
            {report && <span>{normalizeDate(report.created_datetime)}</span>}
          </div>
          <div className={styles.description_item}>
            <CategoryIcon className={styles.description_icon} />
            <MaroSelect
              value={reportValues.maro_directory}
              name="maro_directory"
              onChange={handleChangeSelect}
            />
          </div>
          <div className={styles.description_item}>
            <CancelIcon className={styles.description_icon} />
            <span>{report?.damage_radius}</span>
          </div>
          <div className={styles.description_item}>
            <GpsFixedIcon className={styles.description_icon} />
            {isEditLocation ? (
              <TextField
                value={reportValues.location}
                variant="standard"
                onChange={handleChangeField}
                onBlur={() => setIsEditLocation(false)}
                name="location"
              />
            ) : (
              <span>{shortLocationString}</span>
            )}
            <IconButton onClick={() => setIsEditLocation(true)}>
              <EditIcon />
            </IconButton>
          </div>
          <div className={styles.description_item}>
            <PersonIcon className={styles.description_icon} />
            <span>{`${report?.contact_data.first_name} ${
              report?.contact_data.middle_name ?? ''
            } ${report?.contact_data.last_name}`}</span>
          </div>
          <div className={styles.description_item}>
            <PhoneIcon className={styles.description_icon} />
            <span>{report?.contact_data.phone}</span>
          </div>
          <div className={styles.description_item}>
            <LocationOnIcon className={styles.description_icon} />
            <span>{report?.address || `(${LABELS.noValue})`}</span>
          </div>
          <div className={styles.description_item}>
            <Description className={styles.description_icon} />
            <div>
              {report?.maro && (
                <p>{`${REPORT_MODAL.userMaroText} ${report?.maro.name}.`}</p>
              )}
              {report?.description && (
                <ShowMoreText
                  text={report.description}
                  className={styles.desc_text}
                />
              )}
              {!report?.description && !report?.maro && (
                <span> {`(${LABELS.noValue})`}</span>
              )}
            </div>
          </div>
        </div>
        <div className={styles.view_wrapper}>
          <ReportView
            image={report?.image || ''}
            location={reportValues.location}
          />
        </div>
      </div>
      <ReportFooter onSave={handleSendData} onReset={handleResetData} />
    </Modal>
  )
}
