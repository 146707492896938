/* eslint-disable prettier/prettier */
import { MAX_CHARACTERS } from 'src/constants/form'

export const CONSTANTS = {
  LABELS: {
    login: 'Логін',
    password: 'Пароль',
    passwordConfirm: 'Повторіть пароль',
    name: 'Ім’я',
    middleName: 'По батькові',
    lastName: 'Прізвище',
    rank: 'Звання',
    badgeNumber: 'Номер жетону',
    region: 'Область',
    search: 'Пошук...',
    filter: 'Фільтр',
    selectRegion: 'Оберіть область',
    noValue: 'не зазначено',
    phone: 'Номер телефону',
    loading: 'Завантаження...',
    address: 'Найближча адреса',
    description: 'Опис об’єкта',
    location: 'Геолокація',
    maroType: 'Тип об\'єкта'
  },
  CONTROLS: {
    login: 'Увійти',
    register: 'Зареєструватися',
    profile: 'Мій профіль',
    logout: 'Вихід',
    save: 'Зберегти',
    showMore: 'Читати далі',
    showLess: 'Сховати',
    decline: 'Відмінити',
    addFile: 'Завантажити фото'
  },
  FIELD_ERRORS: {
    required: 'Це поле обов\'язково',
    password:
      'Не меньш 8 символів, одна велика літера, одна маленька, одна цифра та один з символів !@#$%^&*',
    passwordConfirm: 'Паролі мають бути однакові',
    loginError: 'Такий логін вже існує',
    maxCharacters: `Це поле має бути менше ${MAX_CHARACTERS} символів`,
    phone: 'Невірний номер'
  },
  HEADER: {
    dsns: 'ДСНС',
    clearMine: 'Розмінування',
    region: 'Регіон'
  },
  SIDEBAR: {
    MENU: {
      home: 'головна',
      reports: 'запити',
      map: 'мапа',
    },
    BUTTONS: {
      newReport: 'Новий запит',
      hide: 'Приховати',
      show: 'Відкрити меню',
    },
  },
  REPORTS_PAGE: {
    FILTER_TABS: {
      TOTAL: 'Всі',
      NEW: 'Нові',
      CONFIRMED: 'Підтверджені',
      DUPLICATED: 'Дублікат',
      DECLINED: 'Відхилені',
      NOT_ACTUALLY_FOUND: 'Не знайдені',
      ACTUALLY_FOUND: 'Знайдені',
      DISPOSED: 'Знешкоджені',
    },
    excelExport: 'експортувати в Excel',
    mainHeader: 'Запити',
  },
  REGISTRATION_SUCCESS: {
    header: 'Акаунт успішно зареєстрований',
    checkText:
      'Обліковий запис буде активовано після перевірки системного адміністратора',
    loginLink: 'Вхід в систему',
  },
  ERROR_MESSAGES: {
    wrongUserData: 'Не вірно ім\'я або пароль',
    formError: 'Не вірно заповнена форма',
    sendError: 'Сталася помилка під час відправлення форми '
  },
  REPORT_STATUS_LABEL: {
    NEW: 'Новий',
    CONFIRMED: 'Підтверджений',
    DECLINED: 'Відхилений',
    ACTUALLY_FOUND: 'Знайдений',
    NOT_ACTUALLY_FOUND: 'Не знайдений',
    DISPOSED: 'Знешкоджений',
    DUPLICATED: 'Дублікат',
  },
  REPORT_MODAL: {
    userMaroText: 'Користувач вважає що це ',
    photo: 'Фото об’єкта',
    imgAlt: 'Не вдалося завантажити фото',
    map: 'мапа',
    noPhoto: 'Немає фото',
    noPhotoDescription: 'Заявник не додав фото під час заповнення заявки',
    request: 'Запит',
    addressTitle: 'Опишіть точне місцезнаходження',
    locationTitle: 'Оберіть точку на мапі або вставте текст'
  },
  TOAST: {
    success: 'Дані збережено',
    error: 'Не вдається зберегти зміни. Повторіть спробу пізніше.'
  }
}
