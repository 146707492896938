import React from 'react'

import { Page } from 'src/components/Page'
import { MapPage } from 'src/features/Map'

export const Map: React.FC = () => {
  return (
    <Page>
      <MapPage />
    </Page>
  )
}
