import GoogleMapReact from 'google-map-react'
import React, { useCallback, useEffect, useState } from 'react'

import { DEFAULT_ZOOM_LEVEL, MAP_DEFAULT_CENTER } from 'src/constants/map'

import { generateInfoTooltipContent } from './helpers'
import { Marker, OnBoundsChandedEvent } from './types'

import styles from './GoogleMap.module.scss'

import './GoogleMap.general.scss'

interface GoogleMapProps {
  markers: Marker[]
  center?: GoogleMapReact.Coords
  onBoundsChanged?: (val: OnBoundsChandedEvent) => void
  onMapInit?: (map: google.maps.Map) => void
  onClick?: (coord: GoogleMapReact.Coords) => void
  onMarkerClick?: (marker: Marker, cb?: (m: Marker) => void) => void
  onInfoEditClick?: (markerId: number) => void
}

export const GoogleMap = ({
  markers,
  center,
  onBoundsChanged,
  onMapInit,
  onClick,
  onMarkerClick,
  onInfoEditClick,
}: GoogleMapProps) => {
  const [map, setMap] = useState<google.maps.Map>()
  const [googleRef, setGoogleRef] = useState<typeof google.maps>()

  const setGoogleMapRef = useCallback(
    ({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) => {
      const boundsListener = maps.event.addListener(
        map,
        'bounds_changed',
        () => {
          onBoundsChanged?.({
            ...map.getBounds()?.toJSON(),
            zoomLevel: map.getZoom(),
          } as OnBoundsChandedEvent)
        }
      )

      setGoogleRef(maps)
      setMap(map)

      onMapInit?.(map)

      const clickListener = maps.event.addListener(map, 'click', e => {
        onClick?.({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        })
      })
      onBoundsChanged?.({
        ...map.getBounds()?.toJSON(),
        zoomLevel: map.getZoom(),
      } as OnBoundsChandedEvent) // init bounds

      return () => {
        maps.event.removeListener(boundsListener)
        maps.event.removeListener(clickListener)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // draw markers
  useEffect(() => {
    if (!googleRef || !map || markers.length === 0) {
      return
    }

    const infoWindow: google.maps.InfoWindow = new googleRef.InfoWindow()
    const tooltipWindow: google.maps.InfoWindow = new googleRef.InfoWindow({})

    const points: google.maps.Marker[] = markers.map(marker => {
      let refMarker: google.maps.Marker
      if (marker.clusterMetadata) {
        refMarker = new google.maps.Marker({
          position: marker,
          label: {
            text: marker.clusterMetadata?.pointsCount?.toString() ?? '',
            color: 'white',
          },
          map,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 15,
            fillColor: marker.clusterMetadata?.color ?? '#212121',
            fillOpacity: 1,
            strokeWeight: 0.1,
          },
        })

        if (marker.label) {
          refMarker.addListener('mouseover', () => {
            tooltipWindow.setContent(
              `<p class="custom_marker_tooltip">${marker.label}</p>`
            )
            tooltipWindow.open(map, refMarker)
          })

          refMarker.addListener('mouseout', () => {
            tooltipWindow.close()
          })
        }
        refMarker.addListener('click', () => {
          const curZoom = map.getZoom()
          const isClusterWithSinglePoint =
            marker.clusterMetadata?.pointsCount === 1
          map.setZoom(
            curZoom < 16 && !isClusterWithSinglePoint ? curZoom + 1 : 17
          )
          map.panTo(marker)
        })
      } else {
        refMarker = new google.maps.Marker({
          position: marker,
          title: marker.label ?? '',
          map,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8,
            fillColor: '#212121',
            fillOpacity: 1,
            strokeWeight: 0.1,
          },
        })

        if (marker.metadata?.radius) {
          const circle = new googleRef.Circle({
            map,
            radius: marker.metadata?.radius,
            fillColor: marker.metadata?.color,
            strokeColor: marker.metadata?.color,
            strokeWeight: 1,
          })
          circle.bindTo('center', refMarker, 'position')
          circle.bindTo('map', refMarker, 'map')
        }

        if (marker?.metadata?.withInfoWindow) {
          refMarker.addListener('click', () => {
            onMarkerClick?.(marker, m => {
              infoWindow.setContent(
                generateInfoTooltipContent(m, () => {
                  onInfoEditClick?.(m.id as number)
                  infoWindow.close()
                })
              )
              infoWindow.open(map, refMarker)
            })
          })
        }
      }
      return refMarker
    })

    return () => {
      points.forEach(el => {
        el.setMap(null)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers, googleRef, map])

  useEffect(() => {
    if (center) {
      map?.setCenter(center)
    }
  }, [center, map])

  return (
    <div className={`${styles.map_wrapper} custom_google_map`}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
        }}
        defaultCenter={MAP_DEFAULT_CENTER}
        defaultZoom={DEFAULT_ZOOM_LEVEL}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={setGoogleMapRef}
      ></GoogleMapReact>
    </div>
  )
}
