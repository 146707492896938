import { connectRouter, routerMiddleware } from 'connected-react-router'
import { combineReducers } from 'redux'

import {
  Action,
  ThunkAction,
  configureStore,
  createSlice,
} from '@reduxjs/toolkit'

import { UserSlice } from 'src/features/User'

import { HistoryReferrerSlice } from './features/HistoryReferrer'
import { MapPageSlice } from './features/Map/slice'
import { ReportsSlice } from './features/Reports/slice'
import { history } from './routes'

const LayoutSlice = createSlice({
  name: 'layout',
  initialState: { isCloseSidebar: false },
  reducers: {
    toggleSidebar(state) {
      state.isCloseSidebar = !state.isCloseSidebar
    },
  },
})
export const { toggleSidebar } = LayoutSlice.actions

export const rootReducer = combineReducers({
  router: connectRouter(history),
  referrer: HistoryReferrerSlice.reducer,
  user: UserSlice.reducer,
  reports: ReportsSlice.reducer,
  layout: LayoutSlice.reducer,
  map: MapPageSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaults => getDefaults().concat(routerMiddleware(history)),
})

// ---

declare global {
  type AppDispatch = typeof store.dispatch
  type AppState = ReturnType<typeof store.getState>
  type AppSelector<T = unknown> = (state: AppState) => T
  type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action<string>
  >
}

// With this, `useSelector(state => ...)` automatically infers `state` param as `AppState`
declare module 'react-redux' {
  interface DefaultRootState extends AppState {}
}
