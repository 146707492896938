import { MouseEvent, useState } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { REPORT_STATUS } from 'src/constants'
import { ReportStatus } from 'src/features/Reports/types'

import styles from './StatusButton.module.scss'

interface IStatusButtonProps {
  defaultValue: ReportStatus
  onChange: (value: ReportStatus) => void
}

export const StatusButton = (props: IStatusButtonProps) => {
  const { defaultValue, onChange } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const selectedValue = REPORT_STATUS.find(item => item.value === defaultValue)
  const color = selectedValue?.color

  return (
    <div>
      <Button
        variant="outlined"
        style={{
          color,
          borderRadius: 16,
          borderColor: color,
          textTransform: 'capitalize',
        }}
        aria-controls={open ? 'status-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selectedValue?.label}
      </Button>
      <Menu
        open={open}
        id="status-menu"
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {REPORT_STATUS.map(({ value, label, color }) => (
          <MenuItem
            key={value}
            onClick={() => {
              setAnchorEl(null)
              onChange(value)
            }}
            className={styles.menuitem}
            disabled={!selectedValue?.changeTo.includes(value)}
          >
            <span
              className={styles.circle}
              style={{ background: color }}
            ></span>
            <span>{label}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
