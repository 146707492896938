import React, { ReactNode } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MaterialModal from '@mui/material/Modal'

import Loader from '../Loader'

import styles from './Modal.module.scss'

interface ModalProps {
  children?: ReactNode | ReactNode[]
  onClose: () => void
  isOpen: boolean
  isLoading?: boolean
}
export const Modal = ({ children, onClose, isOpen, isLoading }: ModalProps) => {
  return (
    <MaterialModal open={isOpen}>
      <Box className={styles.modal}>
        <IconButton className={styles.close_btn} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {children}
        {isLoading && (
          <div className={styles.loader}>
            <Loader center />
          </div>
        )}
      </Box>
    </MaterialModal>
  )
}
