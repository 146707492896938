import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import {
  ErrorBoundary,
  setDefaultErrorBoundaryFallback,
} from 'react-app-error-boundary'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import AppRouter from 'src/Router'
import { ErrorMessage, Loader, Suspense, Theme } from 'src/components'
import { ReferrerTracker } from 'src/features/HistoryReferrer'
import { clearError, getCurrentRegion, getCurrentUser } from 'src/features/User'
import { history } from 'src/routes'

import { useAppDispatch, useOnMount } from './hooks'

setDefaultErrorBoundaryFallback(({ error }) => (
  <ErrorMessage>{error.message}</ErrorMessage>
))

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  useOnMount(() => {
    dispatch(getCurrentUser()).then(({ payload }) => {
      if (payload) {
        dispatch(getCurrentRegion())
      } else {
        dispatch(clearError())
      }
    })
  })

  return (
    <Guard>
      <ConnectedRouter history={history}>
        <ReferrerTracker />
        <Theme>
          <AppRouter />
        </Theme>
        <ToastContainer position="top-center" />
      </ConnectedRouter>
    </Guard>
  )
}

const Guard: React.FC = props => {
  const { children } = props
  return (
    /* for errors at the very top, display error-overlay as normal */
    <ErrorBoundary allowDevErrorOverlay>
      <Suspense fallback={<Loader center />}>{children}</Suspense>
    </ErrorBoundary>
  )
}

export default App
