import clsx from 'clsx'

import { Sidebar } from '../Sidebar'
import { Header } from '../base/Header'

import { IPageProps } from './types'

import styles from './Page.module.scss'

export function Page(props: IPageProps) {
  const { title, children, center = false } = props
  return (
    <>
      <Header />
      <div className={styles.wrapper}>
        <Sidebar />
        <div
          className={clsx(styles.page, {
            [styles.page_center]: center === true,
            [styles.page_center_v]: center === 'v',
            [styles.page_center_h]: center === 'h',
          })}
        >
          <If condition={title !== undefined}>
            <h2 className={styles.page_title}>{title}</h2>
          </If>
          {children}
        </div>
      </div>
    </>
  )
}
