import Button from '@mui/material/Button'

import { CONSTANTS } from 'src/constants'

import styles from './ReportFooter.module.scss'

const { CONTROLS } = CONSTANTS
interface ReportFooterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave: (props: any) => void
  onReset: () => void
}
export const ReportFooter = ({ onSave, onReset }: ReportFooterProps) => {
  return (
    <footer className={styles.footer}>
      <Button onClick={onReset}>{CONTROLS.decline}</Button>
      <Button variant="contained" onClick={onSave}>
        {CONTROLS.save}
      </Button>
    </footer>
  )
}
