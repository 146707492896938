export interface IReportProps {
  reportKey: number
  onClose: (pk: number | null) => void
}

export type IReportValues = {
  location: string
  maro_directory: number | string
}

export const reportValuesDefault: IReportValues = {
  location: '',
  maro_directory: '',
}
