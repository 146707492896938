import { toast } from 'react-toastify'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { CONSTANTS } from 'src/constants'
import { getFormError } from 'src/utils'

import * as API from './api'
import { IGetReportParams } from './types'

const { TOAST } = CONSTANTS
export const getReports = createAsyncThunk(
  'reports/fetch',
  async (params?: IGetReportParams) => {
    return await API.getReports(params)
  }
)

export const getReport = createAsyncThunk(
  'report/fetch',
  async (pk: number) => {
    return await API.getReport(pk)
  }
)

export const patchReport = createAsyncThunk(
  'report/patch',
  async (
    props: { pk: number; payload: Record<string, number | string> },
    { getState }
  ) => {
    const { reports } = getState() as AppState
    try {
      await API.patchReport(props.pk, props.payload)
      toast.success(TOAST.success)
    } catch (error) {
      const err = error as AxiosError
      toast.error(getFormError(err))
      return
    }
    return await API.getReports(reports.filter)
  }
)
