import { NavLink } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import MapIcon from '@mui/icons-material/Map'
import TableChartIcon from '@mui/icons-material/TableChart'
import { IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'

import { CONSTANTS } from 'src/constants'
import { CreateReport } from 'src/features/Reports/components/CreateReport'
import { toggleCreateReport } from 'src/features/Reports/slice'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import routes from 'src/routes'
import { toggleSidebar } from 'src/store'

import styles from './Sidebar.module.scss'

const {
  SIDEBAR: { MENU, BUTTONS },
} = CONSTANTS

export const Sidebar = () => {
  const isClose = useAppSelector(state => state.layout.isCloseSidebar)
  const report = useAppSelector(state => state.reports)

  const dispatch = useAppDispatch()
  const handleAddReport = () => dispatch(toggleCreateReport(true))

  return (
    <aside
      className={clsx(styles.sidebar, { [styles.sidebar_close]: isClose })}
    >
      <div className={styles.new_request}>
        {isClose ? (
          <IconButton onClick={handleAddReport} aria-label="add">
            +
          </IconButton>
        ) : (
          <Button onClick={handleAddReport} startIcon="+" variant="text">
            {BUTTONS.newReport}
          </Button>
        )}
      </div>
      <nav className={styles.menu}>
        <ul>
          <li>
            <NavLink to={routes.home} className={getClassName} exact>
              <TableChartIcon />
              {!isClose && <span>{MENU.reports}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink to={routes.map} className={getClassName}>
              <MapIcon />
              {!isClose && <span>{MENU.map}</span>}
            </NavLink>
          </li>
        </ul>
      </nav>
      {isClose ? (
        <Tooltip title={BUTTONS.show} placement="right">
          <IconButton
            className={styles.back}
            onClick={() => dispatch(toggleSidebar())}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          className={styles.back}
          startIcon={<ArrowBackIcon />}
          variant="text"
          onClick={() => dispatch(toggleSidebar())}
        >
          {BUTTONS.hide}
        </Button>
      )}
      {report.isShowCreateReport && <CreateReport />}
    </aside>
  )
}

function getClassName(isActive: boolean) {
  return clsx(styles.link, { [styles.active_link]: isActive })
}
