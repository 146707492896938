import * as Yup from 'yup'

import { CONSTANTS, MAX_CHARACTERS, PHONE_REG_EXP } from 'src/constants'
import * as FIELD_NAMES from 'src/utils/fields'

const { LABELS, FIELD_ERRORS } = CONSTANTS

export const FIELDS_DATA: Array<{
  label: string
  name: keyof InitialValues['contact_data']
}> = [
  { label: `${LABELS.lastName}*`, name: FIELD_NAMES.LAST_NAME },
  { label: `${LABELS.name}*`, name: FIELD_NAMES.FIRST_NAME },
  { label: LABELS.middleName, name: FIELD_NAMES.MIDDLE_NAME },
  { label: `${LABELS.phone}*`, name: FIELD_NAMES.PHONE },
]

export interface InitialValues {
  contact_data: {
    [FIELD_NAMES.FIRST_NAME]: string
    [FIELD_NAMES.MIDDLE_NAME]: string
    [FIELD_NAMES.LAST_NAME]: string
    [FIELD_NAMES.PHONE]: string
  }
  [FIELD_NAMES.MARO]: string
  [FIELD_NAMES.ADDRESS]: string
  [FIELD_NAMES.DESCRIPTION]: string
  [FIELD_NAMES.LOCATION]: string
}

export const initialValues: InitialValues = {
  contact_data: {
    [FIELD_NAMES.FIRST_NAME]: '',
    [FIELD_NAMES.MIDDLE_NAME]: '',
    [FIELD_NAMES.LAST_NAME]: '',
    [FIELD_NAMES.PHONE]: '',
  },
  [FIELD_NAMES.MARO]: '',
  [FIELD_NAMES.ADDRESS]: '',
  [FIELD_NAMES.DESCRIPTION]: '',
  [FIELD_NAMES.LOCATION]: '',
}

export const validationSchema = Yup.object({
  contact_data: Yup.object({
    [FIELD_NAMES.FIRST_NAME]: Yup.string()
      .trim()
      .max(MAX_CHARACTERS, FIELD_ERRORS.maxCharacters)
      .required(FIELD_ERRORS.required),
    [FIELD_NAMES.LAST_NAME]: Yup.string()
      .trim()
      .max(MAX_CHARACTERS, FIELD_ERRORS.maxCharacters)
      .required(FIELD_ERRORS.required),
    [FIELD_NAMES.PHONE]: Yup.string()
      .matches(PHONE_REG_EXP, FIELD_ERRORS.phone)
      .required(FIELD_ERRORS.required),
  }),
  [FIELD_NAMES.MARO]: Yup.string().required(FIELD_ERRORS.required),
  [FIELD_NAMES.LOCATION]: Yup.string()
    .trim()
    .max(MAX_CHARACTERS, FIELD_ERRORS.maxCharacters)
    .required(FIELD_ERRORS.required),
})
