import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAppSelector } from './hooks'
import { LoginPage } from './pages/Login'
import { Map } from './pages/Map'
import { Registration } from './pages/Registration'
import { RegistrationSuccess } from './pages/RegistrationSuccess'
import { Reports } from './pages/Reports'
import routes from './routes'

export default function AppRouter() {
  const user = useAppSelector(state => state.user)

  if (user.data?.operator) {
    return (
      <Switch>
        <Route path={routes.home} component={Reports} exact />
        <Route path={routes.map} component={Map} />
        <Redirect to={routes.home} />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path={routes.login} component={LoginPage} />
      <Route path={routes.registration} component={Registration} exact />
      <Route
        path={routes.registrationSuccess}
        component={RegistrationSuccess}
      />
      <Redirect to={routes.login} />
    </Switch>
  )
}
